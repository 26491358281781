body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.panel-body {
  padding:1.5rem;
}
.panel-btnform {
  background:rgba(0,0,0,0.025);
  padding:1rem;
}
.btn-block {
  margin:1rem;
  color:gray;
  border-top:solid 1px rgba(0,0,0,0.05);
  padding:0.5rem
}
.panel-body hr {
  background-color:rgba(0,0,0,0.25)
}
.panel-body h2 {
  color:blueviolet;
}
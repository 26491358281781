@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  color:gray;
  font-family: Oswald,sans-serif !important;
}
h1, h2, h3, h4, h5 {
  font-family: Oswald,sans-serif !important; 
}
a {
  text-decoration: none !important;
  color:BlueViolet !important;
}
.topnav {
  background-color: BlueViolet;
  box-shadow:2px 2px 2px 1px rgb(79, 0, 79) !important;
  z-index:99;
  padding-top:0rem !important;
}
.topnav-brand {
  text-align: center;
  margin-left:2rem;
}
.topnav-brand span {
  color:#FFF;
  font-size: 22px;
}
.main-text {
  margin:1rem;
  width:50%;
  text-align: center;
  margin:auto;
  padding:1rem;
  margin-top:20px;
  color:gray;
}
.main-text h1 {
  color:#000;
}

@media (max-width: 767px) {
  .main-text {
    width:100% !important;
  }
}

.global-loader {
  position: absolute;
  left: 0;
  top:0;
  text-align: center;
  width:100%;
  height:100%;
  background:#FFF;
  z-index:100;
}

.user-block {
  background:#FFF;
  padding:1rem;
  margin:0.5rem;
  border:solid 1px rgba(0,0,0,0.1);
  border-radius:5px;
}
.user-block-content {
  margin-top:1rem;
}
.user-block-content p {
  font-weight:bold;
}
.user-block-content hr {
  background-color:rgba(0,0,0,0.3)
}
.white-block-generalf {
  background:#FFF;
  margin:0.5rem;
  margin-top:1.5rem;
  padding:1rem;
  color:gray;
  border:solid 1px rgba(0,0,0,0.1);
  border-radius:5px;
}
.white-block-generalf .row {
  border-bottom:solid 1px rgba(0,0,0,0.05);
  padding:0.5rem;
  margin:0.5rem !important;
}
.wbg-title {
  font-weight:bold;
  color:#000;
  text-transform: uppercase;
  font-size:20px;
}
.white-block-generalf h3 {
  padding-top:0.5rem;
}

.white-block-general {
  background:#FFF;
  margin:0.5rem;
  padding:1rem;
  color:gray;
  border:solid 1px rgba(0,0,0,0.1);

}
.white-block-general .row {
  padding:0.5rem;
  margin:0.5rem;
}
.white-block-general h3 {
  padding-top:0.5rem;
}

.white-block-general .btn-dark {
  position:absolute;
  bottom:0;
  width:100%;
}
.modelinfo-custom {
  padding:0rem !important;
  margin:0rem !important;
  margin-top:1.5rem !important;
  padding-top:1.5rem !important;
  border-top:solid 1px rgba(0,0,0,0.1);
}
.modelinfo-custom .col-sm-4 {
  text-align: left;
  font-weight:300;
}
.modelinfo-custom .col-sm-4 p {
  font-size:13px
}
.modelinfo-custom .col-sm-4 h3 {
  font-weight: bold;
}
.custombtnaff {
  margin:1rem;
}
.model-image {
  border-radius:50% !important;
}
.modelpage {
  padding:1rem;
}
.modelpage h4 {
  margin-top:1rem;
  font-weight:bold;
  color:#000;
}
.modelpage-buttons {
  padding:1rem 0rem;
  margin:1rem 0rem;
}
.modelpage-buttons .btn-light {
  background: #fff;
  margin:0.5rem 0rem;
  padding:1rem 0rem;
  border-radius:10px;
  border:solid 1px rgba(0,0,0,0.1);
}
.modelpage-buttons .btn-light:hover, .btn-light:focus {
  border:solid 1px rgba(0,0,0,0.1);
}
.modelpage-socials .btn {
  font-size:1.5rem !important;
}
.addsomecss_custom {
  margin:0.5rem;
  padding:0.5rem;
}
.addsomecss_custom p {
  margin-top:2rem;
}
.addsomecss_custom hr {
  background-color: rgba(0,0,0,0.25);
}

.btn-primary {
  background:BlueViolet !important;
  border-color:BlueViolet !important
}
.left-landing {
  background:BlueViolet url('https://fansly.com/assets/images/frontpage-banner-2.webp') center no-repeat;
  background-size:cover;
  height:auto;
  position:relative;
}
.row-landing {
  min-height: 100vh;
  height:auto;
}
.row-landing h1 {
  font-weight:500;
  margin:1rem 0rem;
  color:BlueViolet;
}

.slick-prev, .slick-next {
  background: BlueViolet !important;
  border-radius:50%;
}

.model-bg {
  background-image: linear-gradient(to right top, #ff0091, #e50095, #c90098, #ab0099, #8b0098, #862aa3, #813fad, #7d50b5, #997ccb, #b8a7de, #dad3f0, #ffffff);
}

.login-form {
  margin:auto;
  max-width:50%;
  margin-top:3rem;
  border:solid 1px rgbA(0,0,0,0.1);
  padding:1.5rem;
}

.panelmodel-item {
  padding:0.5rem;
  margin-right:0rem;
  margin-left:0rem;
  color:gray;
  border-bottom:solid 1px rgba(0,0,0,0.05);
}
.panelmodel-item label {
  color:#000;
}
.row-landing hr {
  background:rgba(0,0,0,0.2)
}
.service-block {
  padding:1rem;
  margin:1rem;
  color:gray;
}
.service-block h1 {
  color:BlueViolet;
  text-transform: uppercase;
  font-weight:500;
  margin:1rem 0rem;
}
.service-block-gradient {
  background:  url('./images/gradient.png') center center no-repeat;
  background-size: cover;
  position: relative;
  width:250px;
  height:250px;
}
.service-block-gradient h1 {
  position: absolute;
  left: 52%;
  top: 40%;
  transform: translate(-52%,-40%);
  background:rgba(138, 43, 226, 0.75);
  color:#FFF;
  padding:1rem;
  border-radius:50%;
}
.service-block .btn {
  padding:0.5rem 1.5rem;
  text-transform: uppercase;
}

@keyframes glowing {
  0% { box-shadow: 0 0 -10px BlueViolet; }
  40% { box-shadow: 0 0 20px BlueViolet; }
  60% { box-shadow: 0 0 20px BlueViolet; }
  100% { box-shadow: 0 0 -10px BlueViolet; }
}

.button-glow {
  animation: glowing 5000ms infinite;
}

.service-block p {
  font-weight:300;
}

.services-page-bg {
  background:  url('./images/services-top.jpg') center center no-repeat;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.services-page-cont {
  padding-bottom:2rem;
}
.services-page-cont h1 {
  color:#FFF;
  text-align: center;
  padding:1rem;
  text-shadow:1px 1px 1px rgba(0,0,0,0.25);
  text-transform: uppercase;
  font-weight:700;
  padding-top:2rem;
}
.services-page-cont .customp {
  text-align: center;
  color:#FFF;
  font-size:15px;
  font-weight:500;
  text-shadow:1px 1px 1px rgba(0,0,0,0.25);
}

.services-page-cont hr {
  background:rgba(255,255,255,0.75)
}

.services-item-block {
  background:#FFF;
  padding:1rem;
  margin:0.25rem;
  color:silver;
  border-radius:10px;
  border:solid 2px blueviolet;
  box-shadow:1px 2px 4px 4px rgba(0,0,0,0.75);
}

.services-item-block-t {
  font-size:30px;
  color:blueviolet;
  font-weight:700;
  text-align: center;
  padding-bottom:1rem;
  border-bottom:solid 1px blueviolet
}
.services-item-block-d {
  font-size:13px;
  color:gray;
  padding:1rem;
}
.services-item-block-b {
  font-size:12px;
  color:gray;
}
.services-item-block-b h3 {
  font-size: 20px;
  color:blueviolet;
}
.services-item-block-b ul {
  margin-left:-15px;
}
.services-item-block-b ul li {
  color:#000;
}
.services-item-block-b h2 {
  font-size: 25px;
  color:blueviolet;
}
.services-item-block-b .delivery {
  color:blueviolet !important;
  font-size:15px;
  font-weight:bold;
}
.services-item-block hr {
  background: blueviolet;
}
.services-row {
  margin-top:2rem !important;
}
.services-item-block-price {
  position:absolute;
  background:blueviolet;
  padding:0.5rem 1rem;
  color:#FFF;
  font-weight:900;
  font-size:20px;
  margin-left:auto;
  margin-right:auto;
  left:0;
  right:0;
  top:-25px;
  width:100px;
  text-align: center;
  border-radius:10px;
  border:solid 1px rgba(0,0,0,0.1);
  text-shadow: 1px 1px 1px #000;
}

.topnav a {
  color: #fff !important;
  font-family: Oswald,sans-serif;
  text-decoration: none;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 900;
}